<template>
  <div :class="$style.payment">
    <div
      v-if="$store.state.user.restriction_base?.toLowerCase() === 'business'"
      style="width: 450px"
      class="mb-3"
    >
      <a-select
        placeholder="Pilih Warehouse"
        show-search
        option-filter-prop="label"
        @change="handleWarehouse"
      >
        <a-select-option
          v-for="(item, index) in warehouseData"
          :key="index"
          :value="item.id"
          :label="item.name"
        >
          <div>
            {{ item.name }}
          </div>
        </a-select-option>
      </a-select>
    </div>

    <a-row :gutter="24" class="mb-3">
      <a-col :span="8">
        <a-input-search
          :default-value="$route.query.search_number"
          :placeholder="$t('utils.search')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @change="onSearch"
        />
      </a-col>
      <a-col :span="5" :offset="11">
        <a-select
          :default-value="$route.query.paid_at_sort ?? 'asc'"
          class="w-100 select-antd-default"
          :placeholder="$t('finance.payment_status')"
          :options="options"
          @change="onSearchFilter"
        />
      </a-col>
    </a-row>
    
    <a-row class="d-flex align-items-center">
      <a-col :span="12" class="d-flex">
        <!-- Currentlt we don't use this search
        <div :class="$style.searchBox" class="mx-2">
          <a-input type="text" :placeholder="$t('userManage.search')" />
        </div>
        <SearchIcon class="mx-3" style="cursor: pointer" /> -->
      </a-col>
      <a-col :span="12" class="d-flex justify-content-end" style="gap: 0px 10px;">
        <a-button
          :class="$style.buttonConfirm"
          @click.prevent="showModalExportExcel = true"
        >
          {{ $t('warehouse.export_data') }}
        </a-button>
        <a-button
          :class="$style.buttonConfirm"
          @click="handleCreatePayment(true)"
        >
          Create Payment
        </a-button>
      </a-col>
    </a-row>

    <a-table
      class="payment-list mt-3 bg-white"
      :data-source="dataPayment"
      :row-key="(record, index) => index"
      :columns="columns"
      :loading="loading"
      :pagination="false"
      :scroll="scroll"
      :expanded-row-keys="arrExpandedRow"
      @expandedRowsChange="getIndex"
      @expand="getDetail"
    >
      <div slot="expandedRowRender" slot-scope="record">
        <a-skeleton :loading="loading">
          <div
            v-if="
              (record.payment?.term === 'CBD' ||
                record.payment?.term === 'COD' ||
                (record.payment?.term === 'CAD' &&
                  record.is_paid_first === true)) &&
                loading === false
            "
            class="d-flex"
          >
            <a-col :span="12" class="d-flex flex-column">
              <a-col :span="24" class="d-flex px-2 mb-2">
                <a-col :span="10">
                  Order Number
                </a-col>
                <a-col :span="14" class="d-flex">
                  {{ record.orders[0].order_number }}
                </a-col>
              </a-col>
              <a-col :span="24" class="d-flex px-2">
                <a-col :span="10">
                  Invoice Number
                </a-col>
                <a-col :span="14" class="d-flex">
                  {{ record.invoice_number }}
                </a-col>
              </a-col>
            </a-col>

            <a-col
              :span="12"
              class="d-flex flex-column align-items-end"
            >
              <div class="d-flex mb-2" style="gap: 0px 15px;">
                <div>Total Tagihan</div>
                <div class="mr-2">
                  {{ `Rp. ${toCurrency(record.order_amount)}` }}
                </div>
              </div>
              <div class="payment-tag rounded px-2">
                {{ `${record.payment_name_tag}${record.payment?.term === 'CAD' && record?.is_paid_first === true ? ' - MIX' : ''}` }}
              </div>
            </a-col>
          </div>

          <div
            v-if="
              record.payment?.term === 'CAD' &&
                record.is_paid_first === false &&
                loading === false
            "
            class="d-flex"
          >
            <a-col :span="12" class="d-flex flex-column">
              <a-col :span="24" class="d-flex px-2">
                <a-col :span="10">
                  Total Order Terbayar
                </a-col>
                <a-col :span="14" class="d-flex">
                  <div class="mr-2">
                    {{ `${record.order_count}` }}
                  </div>
                  <div
                    v-if="record.payment?.term === 'CAD'"
                    :class="$style.link"
                    @click="handleModalTagihan(true, record)"
                  >
                    Lihat
                  </div>
                </a-col>
              </a-col>
            </a-col>

            <a-col
              :span="12"
              class="d-flex flex-column justify-content-start align-items-center"
            >
              <a-col
                :span="24"
                class="d-flex flex-column align-items-end"
              >
                <div class="d-flex mb-2" style="gap: 0px 15px;">
                  <div>Total Tagihan</div>
                  <div class="mr-2">
                    {{ `Rp. ${toCurrency(record.total_cad_amount)}` }}
                  </div>
                </div>
                <div class="payment-tag rounded px-2">
                  {{ `${record.payment_name_tag}${record.payment?.term === 'CAD' && record?.is_paid_first === true ? ' - MIX' : ''}` }}
                </div>
              </a-col>
            </a-col>
          </div>
        </a-skeleton>
      </div>
    </a-table>

    <!-- Modal Create Payment -->
    <ModalCreatePayment
      v-if="modalCreatePayment"
      @handleCreatePayment="handleCreatePayment"
    />

    <!-- Modal Order Paid -->
    <ModalOrderPaid
      v-if="modalOrderPaid"
      :list="listTagihan"
      :loading="loading"
      :pages="pages"
      :row="rowData"
      :paginations="paginationTagihan"
      @handleOrderPaid="handleOrderPaid"
    />

    <!-- pagination -->
    <Pagination
      class="text-right"
      :total="pagination.total_items"
      :page-size="pagination.size"
      :total-length-per-page="dataPayment?.length"
      :default-page="page"
      @changePage="changePage"
    />

    <ModalExportExcel
      :visible="showModalExportExcel"
      :on-close="onCloseModalExportExcel"
      :is-loading="loading"
      @handleSubmitModal="exportDataTable"
    />
  </div>
</template>

<script>
import historyPayment from '../composables/historyPayment'
import ModalCreatePayment from './ModalCreatePayment.vue'
import ModalOrderPaid from './ModalOrderPaid.vue'
import Pagination from '@/components/Pagination/index.vue'
import ModalExportExcel from '@/views/finance/ModalExportExcel'
import XLSX from 'xlsx';
import { useCurrency } from '@/composable/useCurrency'
import { getPaymentHistory } from '@/api/channels/distributor'

const { format } = useCurrency()

export default {
  name: 'PaymentHistory',
  components: {
    ModalCreatePayment,
    ModalOrderPaid,
    Pagination,
    ModalExportExcel,
  },
  setup() {
    const {
      dataPayment,
      modalCreatePayment,
      modalOrderPaid,
      warehouseData,
      page,
      pages,
      pagination,
      paginationTagihan,
      getListHistoryPayment,
      initialData,
      listTagihan,
      loading,
      changePage,
      handleOrderPaid,
      handleCreatePayment,
      handleWarehouse,
      getInvoiceNumber,
      fetchListTagihan,
      sellerId,
      businessId,
    } = historyPayment()

    return {
      dataPayment,
      modalCreatePayment,
      modalOrderPaid,
      warehouseData,
      page,
      pages,
      pagination,
      paginationTagihan,
      getListHistoryPayment,
      initialData,
      listTagihan,
      loading,
      changePage,
      handleOrderPaid,
      handleCreatePayment,
      handleWarehouse,
      getInvoiceNumber,
      fetchListTagihan,
      sellerId,
      businessId,
    }
  },
  data: function () {
    return {
      lastIndex: null,
      columns: [
        {
          title: 'No. Pembayaran',
          dataIndex: 'payment_number',
          key: 'payment_number',
        },
        {
          title: 'Total Bayar',
          dataIndex: 'payment_amount',
          key: 'payment_amount',
          ellipsis: true,
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp) return 'Rp. ' + temp
            else return '-'
          },
        },
        {
          title: 'Tanggal Bayar',
          dataIndex: 'paid_at',
          key: 'paid_at',
          customRender: value => {
            const time =
              value && this.$moment(value).format('DD MMM YYYY HH:mm')
            if (time) return time
            else return '-'
          },
        },
        {
          title: 'Metode Pembayaran',
          dataIndex: 'payment_type',
          key: 'payment_type',
          ellipsis: true,
        },
        {
          title: 'Nama Pembayaran',
          dataIndex: 'payment_name',
          key: 'payment_name',
          ellipsis: true,
        },
      ],
      rowData: {},
      showModalExportExcel: false,
      arrExpandedRow: [],
      screenHeight: screen.height - 520,
      options: [
        { label: 'Tanggal Terlama', value: 'asc' },
        { label: 'Tanggal Terbaru', value: 'desc' },
      ],
      isLoading: false,
    }
  },
  computed: {
    scroll() { 
      return { y: this.screenHeight }
    },
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.getIndex([])
      },
    },
  },
  mounted() {
  },
  methods: {
    handleModalTagihan(visible, item) {
      const page = 1
      this.fetchListTagihan(item, page)
      this.handleOrderPaid(visible)
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    getIndex(item) {
      this.arrExpandedRow = item
      if (item.slice(-1)[0] !== undefined) {
        const temp = item.slice(-1)[0]
        this.lastIndex = temp
      }
    },
    getDetail(visible, record) {
      if (
        (this.lastIndex !== null || this.lasIndex !== undefined) &&
        visible === true
      ) {
        this.fetchListTagihan(record, 1)
        this.getInvoiceNumber(record, this.lastIndex)
      }
      this.rowData = record
    },
    onCloseModalExportExcel() {
      this.showModalExportExcel = false
    },
    async exportDataTable(date) {
      this.loading = true
      const { business_id } = this.$route.query
      await getPaymentHistory({
        business_id: business_id,
        id: this.$route.params.id,
        seller_id: this.sellerId,
        params: {
          limit: this.pagination.total_items,
          page: 1,
          search: undefined,
        },
      })
      .then(({ data: { data: response } }) => {
        const filteredDataByDate = response.contents.filter((obj) => this.$moment(obj.paid_at).isBetween(date.startDate, date.endDate, 'days', '[]'))
        const flattenData = []
        filteredDataByDate.forEach((obj) => {
          if (obj.orders?.length > 0) {
            obj.orders.forEach((order) => {
              flattenData.push({
                payment_number: obj.payment_number,
                payment_id: obj.payment_id,
                payment_amount: obj.payment_amount,
                payment_paid_amount: obj.payment_paid_amount,
                created_at: this.$moment(obj.paid_at).format('DD MMM YYYY, HH:mm'),
                payment_type: obj.payment_type,
                account_number: obj.account_number ?? '-',
                payment_name: obj.payment_name,
                order_number: order.order_number,
                order_id: order.order_id,
                order_date: this.$moment(order.created_at).format('DD MMM YYYY, HH:mm'),
              })
            })
          } else {
            flattenData.push({
              payment_number: obj.payment_number,
            })
          }
        })
        const rowData = [
          "No. Pembayaran",
          "ID Pembayaran",
          "Total Bayar",
          "Total yang Sudah Dibayar",
          "Tanggal Bayar",
          "Metode Pembayaran",
          "Nomor Akun",
          "Nama Pembayaran",
          "No. Order",
          "ID Order",
          "Tanggal Order",
        ]
        flattenData.sort((a, b) => {
          return this.$moment(b.paid_at) - this.$moment(a.paid_at);
        })
        const worksheet = XLSX.utils.json_to_sheet(flattenData);
        const workbook = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
        XLSX.writeFile(workbook,'riwayat_pembayaran.xlsx')
      })
      .catch(err => {
        this.loading = false
        console.error(err)
      })
      .finally(() => {
        this.loading = false
        this.showModalExportExcel = false
      })
    },
    onSearch(e) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            search_number: e.target.value,
            page: 1,
          },
        })
      }, 1000)
    },
    onSearchFilter(value) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          paid_at_sort: value,
          page: 1,
        },
      })
    },
  },
}
</script>

<style lang="scss" module>
@import '../style.module.scss';
</style>
<style lang="scss">
.payment-tag {
  background-color: #2196f3;
  color: white;
  min-width: 200px;
  text-align: center;
}
</style>
